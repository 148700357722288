import { FiCheck, FiMinus } from "react-icons/fi";
import thumb1 from "../../images/nft/10.png";
import thumb2 from "../../images/nft/14.png";
import thumb3 from "../../images/nft/32.png";
import thumb4 from "../../images/nft/39.png"; 

const data = [
  {
    title: "Grow -> Mint -> Vibe -> Thrive -> REDACTED",
    thumb: thumb1,
    features: [
      {
        icon: <FiCheck />,
        text: "",
      },
      {
        icon: <FiCheck />,
        text: "",
      },
      // {
      //   icon: <FiCheck />,
      //   text: "Publish Token Name",
      // },
      {
        icon: <FiCheck />,
        text: "",
      },
      // {
      //   icon: <FiCheck />,
      //   text: "BTU Public Coin Launch",
      // },
      // {
      //   icon: <FiCheck />,
      //   text: "Project White Paper",
      // },
    ],
  },

  // {
  //   title: "Phase 02",
  //   thumb: thumb2,
  //   features: [
  //     {
  //       icon: <FiCheck />,
  //       text: "Bithu Dice's & Smart Contract Integration",
  //     },
  //     {
  //       icon: <FiCheck />,
  //       text: "BTU Public Coin Launch",
  //     },
  //     {
  //       icon: <FiCheck />,
  //       text: "World Beta Release 1.0",
  //     },
  //     {
  //       icon: <FiCheck />,
  //       text: "Development of NFT Marketplace",
  //     },
  //     {
  //       icon: <FiCheck />,
  //       text: "BTU Public Coin Launch",
  //     },
  //     {
  //       icon: <FiCheck />,
  //       text: "Smart Contract Creation",
  //     },
  //   ],
  // },

  // {
  //   title: "Phase 03",
  //   thumb: thumb3,
  //   features: [
  //     {
  //       icon: <FiCheck />,
  //       text: "Collabs, and other surprises for BU Members.",
  //     },
  //     {
  //       icon: <FiCheck />,
  //       text: "The Solana Blockchain will be Invaded",
  //     },
  //     {
  //       icon: <FiMinus />,
  //       text: "Collection Launch",
  //     },
  //     {
  //       icon: <FiMinus />,
  //       text: "Rarity chart, Solrarity and Moonrank ranking",
  //     },
  //     {
  //       icon: <FiMinus />,
  //       text: "Exploring the idea of a Token",
  //     },
  //     {
  //       icon: <FiMinus />,
  //       text: "Marketing on social networks",
  //     },
  //   ],
  // },

  // {
  //   title: "Phase 04",
  //   thumb: thumb4,
  //   features: [
  //     {
  //       icon: <FiCheck />,
  //       text: "Creation of the CAT NFT collection",
  //     },
  //     {
  //       icon: <FiCheck />,
  //       text: "Publish Token Name",
  //     },
  //     {
  //       icon: <FiMinus />,
  //       text: "Launching our 5555 free mint For Whitelist",
  //     },
  //     {
  //       icon: <FiMinus />,
  //       text: "Exploring the idea of a Token",
  //     },
  //     {
  //       icon: <FiMinus />,
  //       text: "Project White Paper",
  //     },
  //   ],
  // },
];

export default data;
