const data = [
  {
    title: "What is Pudgymart?",
    text: "The Pinnacle of NFT Mass Adoption",
  },
  {
    title: "What is the tax?",
    text: "0/0",
  },
  {
    title: " When can I expect a roadmap? ",
    text: "No",
  },
  {
    title: " Does it do anything? ",
    text: "Bridges the gap so hard",
  },
 
];

export default data;
