import nftImg1 from "../images/nft/1.png";
import nftImg2 from "../images/nft/2.png";
import nftImg3 from "../images/nft/3.png";
import nftImg4 from "../images/nft/4.png";
import nftImg5 from "../images/nft/84.png";
import nftImg6 from "../images/nft/85.png";
import nftImg7 from "../images/nft/86.png";
// import nftImg8 from "../images/nft/cat8.png";
// import nftImg9 from "../images/nft/cat9.png";
// import nftImg10 from "../images/nft/cat10.png";
// import nftImg11 from "../images/nft/cat11.png";
// import nftImg12 from "../images/nft/cat12.png";

const data = [
  nftImg1,
  nftImg2,
  nftImg3,
  nftImg4,
  nftImg5,
  nftImg6,
  nftImg7,
  // nftImg8,
  // nftImg9,
  // nftImg10,
  // nftImg11,
  // nftImg12,
];

export default data;
